import React from 'react'
import './overview.css'

const Overview = () => {
  return (
    <div className='overview_container p-10'>
        <div className='overview-wrapper'>
            <img src='/images/glance/glance.png' alt='' />
        </div>
    </div>
  )
}

export default Overview